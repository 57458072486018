import { MouseEventHandler } from 'react';

const getLocation = (prefix: string) => `${prefix}/auth/sign_out`;

const createSignoutProps: (prefix?: string) => {
  href: string;
  // making `event` optional in case this is not run as a handler
  onClick: (
    event?: Parameters<MouseEventHandler<HTMLAnchorElement>>[0]
  ) => Promise<void>;
} = (prefix = '') => ({
  href: getLocation(prefix),
  onClick: async event => {
    event?.preventDefault();
    if (window.Verisoul) {
      try {
        await window.Verisoul.reinitialize();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Verisoul failed to reinitialize');
      }
    }
    // @ts-expect-error We can actually assign a string to Location
    window.location = getLocation(prefix);
  },
});

export default createSignoutProps;
