import gql from 'graphql-tag';
import * as Apollo from '@apollo/client';
import * as Types from '../../../server/graphql/__generated.types';

import { ActorFragmentDoc } from '../fragments.generated';

const defaultOptions = {} as const;
export type ProductAccessLimitsFragment = {
  __typename?: 'ProductAccessLimits';
  projectsLimit: number;
  clipGenerationLimit: number;
  editorCharacterLimit: number;
};

export type ProductAccessPeriodUsageFragment = {
  __typename?: 'ProductAccessPeriodUsage';
  id: string;
  projects: number;
  clipsGenerated: number;
  periodStart: number;
  periodEnd: number;
  usageScope: Types.ProductAccessUsageScope;
};

export type ProductAccessPeriodUsageWithLifetimeMetricsFragment = {
  __typename?: 'ProductAccessPeriodUsage';
  id: string;
  joinedAt: string;
  lifetimeCharactersRendered: number;
  projects: number;
  clipsGenerated: number;
  periodStart: number;
  periodEnd: number;
  usageScope: Types.ProductAccessUsageScope;
};

export type ProductUsageQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ProductUsageQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    permissions: Array<string>;
    clipsGenerated: number;
    productAccessLimits: {
      __typename?: 'ProductAccessLimits';
      projectsLimit: number;
      clipGenerationLimit: number;
      editorCharacterLimit: number;
    };
    productAccessPeriodUsage: {
      __typename?: 'ProductAccessPeriodUsage';
      id: string;
      projects: number;
      clipsGenerated: number;
      periodStart: number;
      periodEnd: number;
      usageScope: Types.ProductAccessUsageScope;
    };
    actors: Array<{
      __typename?: 'Actor';
      id: number;
      name: string;
      description: string;
      image: string;
      preview: string;
      previewText: string;
      initials: string;
      accessLevel?: Types.ActorAccessLevel | null;
      availableIn?: Types.SubscriptionTier | null;
      isCustomActor?: boolean | null;
      locale?: Types.Locale | null;
      restrictionTags?: Array<Types.SubscriptionTier> | null;
      tags?: Array<{
        __typename?: 'AvatarTaggingAttribute';
        attribute: string;
        category: Types.AvatarTaggingAttributeCategoryEnum;
        internalTag: boolean;
      }> | null;
      variants: Array<{
        __typename?: 'ActorVariant';
        id: number;
        version: string;
        isBeta?: boolean | null;
        isArchived?: boolean | null;
        locale: string;
        preview?: string | null;
        tags?: Array<{
          __typename?: 'AvatarTaggingAttribute';
          attribute: string;
          category: Types.AvatarTaggingAttributeCategoryEnum;
          internalTag: boolean;
        }> | null;
        userSettings?: {
          __typename?: 'UserAvatarSettings';
          favoritedAt?: string | null;
        } | null;
        teamSettings?: {
          __typename?: 'TeamAvatarSettings';
          favoritedAt?: string | null;
        } | null;
        variantType: {
          __typename?: 'ActorVariantType';
          id: Types.ActorVariantTypeId;
          label: string;
          description?: string | null;
        };
        versions: {
          __typename?: 'ActorVariantVersions';
          previous?: {
            __typename?: 'TTSModelRelease';
            model: string;
            label: string;
            description?: string | null;
            overrideSpeakerVariantId?: number | null;
            features: Array<string>;
            studioProjectVersion?: Types.StudioProjectVersion | null;
          } | null;
          latest: {
            __typename?: 'TTSModelRelease';
            model: string;
            label: string;
            description?: string | null;
            overrideSpeakerVariantId?: number | null;
            features: Array<string>;
            studioProjectVersion?: Types.StudioProjectVersion | null;
          };
          next?: {
            __typename?: 'TTSModelRelease';
            model: string;
            label: string;
            description?: string | null;
            overrideSpeakerVariantId?: number | null;
            features: Array<string>;
            studioProjectVersion?: Types.StudioProjectVersion | null;
          } | null;
        };
      }>;
    }>;
    team?: {
      __typename?: 'Team';
      id: string;
      productAccessLimits: {
        __typename?: 'ProductAccessLimits';
        projectsLimit: number;
        clipGenerationLimit: number;
        editorCharacterLimit: number;
      };
      productAccessPeriodUsage: {
        __typename?: 'ProductAccessPeriodUsage';
        id: string;
        projects: number;
        clipsGenerated: number;
        periodStart: number;
        periodEnd: number;
        usageScope: Types.ProductAccessUsageScope;
      };
    } | null;
  };
};

export type ProductUsageWithLifetimeMetricsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type ProductUsageWithLifetimeMetricsQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    permissions: Array<string>;
    clipsGenerated: number;
    productAccessLimits: {
      __typename?: 'ProductAccessLimits';
      projectsLimit: number;
      clipGenerationLimit: number;
      editorCharacterLimit: number;
    };
    productAccessPeriodUsage: {
      __typename?: 'ProductAccessPeriodUsage';
      id: string;
      joinedAt: string;
      lifetimeCharactersRendered: number;
      projects: number;
      clipsGenerated: number;
      periodStart: number;
      periodEnd: number;
      usageScope: Types.ProductAccessUsageScope;
    };
    actors: Array<{
      __typename?: 'Actor';
      id: number;
      name: string;
      description: string;
      image: string;
      preview: string;
      previewText: string;
      initials: string;
      accessLevel?: Types.ActorAccessLevel | null;
      availableIn?: Types.SubscriptionTier | null;
      isCustomActor?: boolean | null;
      locale?: Types.Locale | null;
      restrictionTags?: Array<Types.SubscriptionTier> | null;
      tags?: Array<{
        __typename?: 'AvatarTaggingAttribute';
        attribute: string;
        category: Types.AvatarTaggingAttributeCategoryEnum;
        internalTag: boolean;
      }> | null;
      variants: Array<{
        __typename?: 'ActorVariant';
        id: number;
        version: string;
        isBeta?: boolean | null;
        isArchived?: boolean | null;
        locale: string;
        preview?: string | null;
        tags?: Array<{
          __typename?: 'AvatarTaggingAttribute';
          attribute: string;
          category: Types.AvatarTaggingAttributeCategoryEnum;
          internalTag: boolean;
        }> | null;
        userSettings?: {
          __typename?: 'UserAvatarSettings';
          favoritedAt?: string | null;
        } | null;
        teamSettings?: {
          __typename?: 'TeamAvatarSettings';
          favoritedAt?: string | null;
        } | null;
        variantType: {
          __typename?: 'ActorVariantType';
          id: Types.ActorVariantTypeId;
          label: string;
          description?: string | null;
        };
        versions: {
          __typename?: 'ActorVariantVersions';
          previous?: {
            __typename?: 'TTSModelRelease';
            model: string;
            label: string;
            description?: string | null;
            overrideSpeakerVariantId?: number | null;
            features: Array<string>;
            studioProjectVersion?: Types.StudioProjectVersion | null;
          } | null;
          latest: {
            __typename?: 'TTSModelRelease';
            model: string;
            label: string;
            description?: string | null;
            overrideSpeakerVariantId?: number | null;
            features: Array<string>;
            studioProjectVersion?: Types.StudioProjectVersion | null;
          };
          next?: {
            __typename?: 'TTSModelRelease';
            model: string;
            label: string;
            description?: string | null;
            overrideSpeakerVariantId?: number | null;
            features: Array<string>;
            studioProjectVersion?: Types.StudioProjectVersion | null;
          } | null;
        };
      }>;
    }>;
    team?: {
      __typename?: 'Team';
      id: string;
      productAccessLimits: {
        __typename?: 'ProductAccessLimits';
        projectsLimit: number;
        clipGenerationLimit: number;
        editorCharacterLimit: number;
      };
      productAccessPeriodUsage: {
        __typename?: 'ProductAccessPeriodUsage';
        id: string;
        joinedAt: string;
        lifetimeCharactersRendered: number;
        projects: number;
        clipsGenerated: number;
        periodStart: number;
        periodEnd: number;
        usageScope: Types.ProductAccessUsageScope;
      };
    } | null;
  };
};

export const ProductAccessLimitsFragmentDoc = gql`
  fragment ProductAccessLimitsFragment on ProductAccessLimits {
    projectsLimit
    clipGenerationLimit
    editorCharacterLimit
  }
`;
export const ProductAccessPeriodUsageFragmentDoc = gql`
  fragment ProductAccessPeriodUsageFragment on ProductAccessPeriodUsage {
    id
    projects
    clipsGenerated
    periodStart
    periodEnd
    usageScope
  }
`;
export const ProductAccessPeriodUsageWithLifetimeMetricsFragmentDoc = gql`
  fragment ProductAccessPeriodUsageWithLifetimeMetricsFragment on ProductAccessPeriodUsage {
    id
    joinedAt
    lifetimeCharactersRendered
    projects
    clipsGenerated
    periodStart
    periodEnd
    usageScope
  }
`;
export const ProductUsageDocument = gql`
  query ProductUsage {
    user {
      id
      permissions
      clipsGenerated
      productAccessLimits {
        ...ProductAccessLimitsFragment
      }
      productAccessPeriodUsage {
        ...ProductAccessPeriodUsageFragment
      }
      actors(inputs: {}) {
        ...ActorFragment
      }
      team {
        id
        productAccessLimits {
          ...ProductAccessLimitsFragment
        }
        productAccessPeriodUsage {
          ...ProductAccessPeriodUsageFragment
        }
      }
    }
  }
  ${ProductAccessLimitsFragmentDoc}
  ${ProductAccessPeriodUsageFragmentDoc}
  ${ActorFragmentDoc}
`;

/**
 * __useProductUsageQuery__
 *
 * To run a query within a React component, call `useProductUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductUsageQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductUsageQuery(
  baseOptions?: Apollo.QueryHookOptions<ProductUsageQuery, ProductUsageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductUsageQuery, ProductUsageQueryVariables>(
    ProductUsageDocument,
    options
  );
}
export function useProductUsageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductUsageQuery, ProductUsageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductUsageQuery, ProductUsageQueryVariables>(
    ProductUsageDocument,
    options
  );
}
export type ProductUsageQueryHookResult = ReturnType<typeof useProductUsageQuery>;
export type ProductUsageLazyQueryHookResult = ReturnType<typeof useProductUsageLazyQuery>;
export type ProductUsageQueryResult = Apollo.QueryResult<
  ProductUsageQuery,
  ProductUsageQueryVariables
>;
export const ProductUsageWithLifetimeMetricsDocument = gql`
  query ProductUsageWithLifetimeMetrics {
    user {
      id
      permissions
      clipsGenerated
      productAccessLimits {
        ...ProductAccessLimitsFragment
      }
      productAccessPeriodUsage {
        ...ProductAccessPeriodUsageWithLifetimeMetricsFragment
      }
      actors(inputs: {}) {
        ...ActorFragment
      }
      team {
        id
        productAccessLimits {
          ...ProductAccessLimitsFragment
        }
        productAccessPeriodUsage {
          ...ProductAccessPeriodUsageWithLifetimeMetricsFragment
        }
      }
    }
  }
  ${ProductAccessLimitsFragmentDoc}
  ${ProductAccessPeriodUsageWithLifetimeMetricsFragmentDoc}
  ${ActorFragmentDoc}
`;

/**
 * __useProductUsageWithLifetimeMetricsQuery__
 *
 * To run a query within a React component, call `useProductUsageWithLifetimeMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductUsageWithLifetimeMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductUsageWithLifetimeMetricsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductUsageWithLifetimeMetricsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProductUsageWithLifetimeMetricsQuery,
    ProductUsageWithLifetimeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProductUsageWithLifetimeMetricsQuery,
    ProductUsageWithLifetimeMetricsQueryVariables
  >(ProductUsageWithLifetimeMetricsDocument, options);
}
export function useProductUsageWithLifetimeMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductUsageWithLifetimeMetricsQuery,
    ProductUsageWithLifetimeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProductUsageWithLifetimeMetricsQuery,
    ProductUsageWithLifetimeMetricsQueryVariables
  >(ProductUsageWithLifetimeMetricsDocument, options);
}
export type ProductUsageWithLifetimeMetricsQueryHookResult = ReturnType<
  typeof useProductUsageWithLifetimeMetricsQuery
>;
export type ProductUsageWithLifetimeMetricsLazyQueryHookResult = ReturnType<
  typeof useProductUsageWithLifetimeMetricsLazyQuery
>;
export type ProductUsageWithLifetimeMetricsQueryResult = Apollo.QueryResult<
  ProductUsageWithLifetimeMetricsQuery,
  ProductUsageWithLifetimeMetricsQueryVariables
>;
