import getConfig from 'next/config';
import { UserSubscriptionFragment } from '../../fragments.generated';

const { CHURNKEY_APP_ID } = getConfig().publicRuntimeConfig;

declare global {
  interface Window {
    churnkey?: any;
  }
}

export type customerAttributes = {
  numberOfClips?: number;
};

/**
 * Checks the status of the Stripe subscription and if it's paused,
 * a pause wall will be presented to the user on all studio pages until
 * the pause is lifted.
 * @see https://docs.churnkey.co/pause-wall
 */
export function checkSubscriptionPause(inputs: {
  subscription: UserSubscriptionFragment;
  handleLogout: () => void;
}) {
  const { subscription, handleLogout } = inputs;

  window.churnkey?.check('pause', {
    customerId: subscription.customerId,
    subscriptionId: subscription.id,
    authHash: subscription.churnkeyAuthHash,
    appId: CHURNKEY_APP_ID,
    mode: 'live',
    provider: 'stripe',
    handleLogout,
  });
}

/**
 * Show the user the Churnkey cancel flow instead of our cancel subscription prompt
 */
export function showChurnkeyCancelFlow(inputs: {
  subscription: UserSubscriptionFragment;
  onCancel: () => void;
  onDiscount: () => void;
  onPlanChange: () => void;
  onPause: () => void;
  customerAttributes?: customerAttributes;
}) {
  const {
    subscription,
    onCancel,
    onDiscount,
    onPlanChange,
    onPause,
    customerAttributes,
  } = inputs;

  window.churnkey.init('show', {
    customerId: subscription.customerId,
    subscriptionId: subscription.id,
    authHash: subscription.churnkeyAuthHash,
    appId: CHURNKEY_APP_ID,
    mode: 'live',
    provider: 'stripe',
    onCancel,
    onDiscount,
    onPlanChange,
    onPause,
    customerAttributes,
  });
}

export function isChurnkeyLoaded() {
  return Boolean(window.churnkey);
}
